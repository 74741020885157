export const getEnv = () => {
  const host = window.location.host;
  if (host.includes('localhost')) return 'local';
  if (host.includes('test')) return 'test';
  if (host.includes('qa')) return 'qa';
  if (host === 'app-hiworkglobal.hiwork.com') return 'demos';
  return 'prod';
};
export const modifyApiHostname = () => {
  const hostname = window.location.hostname;
  const env = getEnv();
  let apiuri = '';
  switch (env) {
    case 'test':
      apiuri = '//' + hostname.replace('test-app', 'test-app-api');
      break;
    case 'qa':
      apiuri = '//' + hostname.replace('qa-app', 'qa-app-api');
      break;
    case 'demos':
      apiuri =
        '//' + hostname.replace('app-hiworkglobal', 'app-hiworkglobal-api');
      break;
    case 'prod':
      apiuri = '//' + hostname.replace('app', 'app-api');
      break;
    case 'local':
      apiuri = '//test-app-api.hiworkglobal.com';
      break;
    default:
      break;
  }
  return apiuri;
};
export const getApiUri = () => {
  // return ''; // 开代理
  const uri = modifyApiHostname();
  return uri;
};

export const modifyLoginHostname = () => {
  const hostname = window.location.hostname;
  const env = getEnv();
  let apiuri = '';
  switch (env) {
    case 'test':
      apiuri = '//' + hostname.replace('test-app', 'test-home') + '/login';
      break;
    case 'qa':
      apiuri = '//' + hostname.replace('qa-app', 'qa-home') + '/login';
      break;
    case 'demos':
      apiuri =
        '//' + hostname.replace('app-hiworkglobal', 'home-global') + '/login';
      break;
    case 'prod':
      apiuri = '//' + hostname.replace('app', 'home') + '/login';
      break;
    case 'local':
      apiuri = '//test-home.hiworkglobal.com/login';
      break;
    default:
      break;
  }
  return apiuri;
};

export const getLoginUri = () => {
  const uri = modifyLoginHostname();
  return uri;
};
const iuris = {
  local: '//test-i.hiworkglobal.com',
  test: '//test-i.hiworkglobal.com',
  demos: '//i-hiworkglobal.hiwork.com',
  qa: '//qa-i.hiworkglobal.com',
  prod: '//i.hiworkglobal.com',
};

export const getIUri = () => {
  const env = getEnv();
  return iuris[env];
};

const ehiworkuris = {
  local: '//test-e.hiwork.com',
  test: '//test-e.hiwork.com',
  demos: '//demos-e.hiwork.com',
  qa: '//qa-e.hiwork.com',
  prod: '//e.hiwork.com',
};

export const getEhiworkUri = () => {
  const env = getEnv();
  return ehiworkuris[env];
};
