import { createApp } from 'vue';
import store from './store/index';
import router from './router';
import { useAuth } from './utils/auth';
import 'virtual:uno.css';
import App from './App.vue';
import i18n from './languages/index';
import './assets/iconFont/iconfont.css';
import './assets/iconFont/iconfont2.css';
import './assets/iconFont/iconfont3.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import './style.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { throttleClick } from '@/directives/throttle';
import CountryFlag from 'vue-country-flag-next';
import * as Sentry from '@sentry/vue';
import pck from '../package.json';
import { getEnv } from '@/utils/envv';
import { initMeasurement } from '@zh/perf';
console.log('环境变量：', import.meta.env);
useAuth(router).then(() => {
  const app = createApp(App);
  app.component('VueCountryFlag', CountryFlag);
  app.directive('throttle', throttleClick);
  app.use(router);
  app.use(store);
  app.use(i18n);
  app.use(VueDOMPurifyHTML);
  app.use(ElementPlus);
  if (getEnv() === 'prod' || getEnv() === 'local') {
    Sentry.init({
      app,
      dsn: 'https://f0720766d0272b73538f8238f31360e3@sentry.hiworkglobal.com/17',
      release: pck.version,
      integrations: [
        Sentry.browserTracingIntegration({ router, routeLabel: 'path' }),
      ],
      environment: getEnv() || 'development',
      tracesSampleRate: 1,
    });
    initMeasurement();
  }

  app.mount('#app');
});
